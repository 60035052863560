<template>
  <div class="animated fadeIn">
    <DataViewWrapper
      :filters="filters"
      :table-columns="tableColumns"
      :model="model"
    />
  </div>
</template>

<script>
import models from "@/models";

export default {
  name: "IncomingOrders",
  components: {},
  data: function() {
    return {
      model: models.warehouse.invoices,
      filters: ["Warehouse", "Invoice Date"],
      tableColumns: [
        "ID",
        "Shipment Date",
        "Invoice Date",
        "PO Number",
        "PO Date",
        "Account Name",
        "Warehouse",
        "Status",
        "Total",
        "Created By"
      ]
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
  watch: {}
};
</script>

<style></style>
